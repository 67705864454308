<section *ngIf="serviceList && contentScully" class="content-call-to-action-squidex" [ngClass]="styleClass">
  <div class="box">
    <ng-container *ngFor="let item of serviceList">
      <div class="box-wrapper">
        <ng-container *ngIf="contentCTA; else defaultContentSquidex">
          <h3 class="box-wrapper__heading"
            ><span> {{ contentCTA | translate }}</span></h3
          >
        </ng-container>
        <ng-template #defaultContentSquidex>
          <h3 class="box-wrapper__heading"
            ><span>
              {{ contentCTA_1 | translate }}&nbsp;<span class="box-wrapper__heading--layout">{{
                isTotalPartners ? item.data.totalPartners.iv : item.data.totalBusinesses.iv
              }}</span
              >&nbsp;{{ contentCTA_2 | translate }}</span
            ></h3
          >
        </ng-template>
        <a
          class="box-wrapper__btn"
          [href]="getLink()"
          rel="noopener noreferrer"
          target="_blank"
          (click)="onSignupEvent()"
          ><span>{{ contentTextLink | translate }}</span></a
        >
      </div>
    </ng-container>
  </div>
</section>
