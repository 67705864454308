import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SquidexService } from '../../../services/squidex.service'
import { Subscription } from 'rxjs'
import { isScullyGenerated, TransferStateService } from '@scullyio/ng-lib'
import { take } from 'rxjs/operators'
import { SignupFlowService } from '@flowaccount/landing'
import { SignupPreviousPath } from '../../../enum/landing-enum'
@Component({
  selector: 'flowaccount-content-call-to-action-squidex',
  templateUrl: './content-call-to-action-squidex.component.html',
  styleUrls: ['./content-call-to-action-squidex.component.scss'],
})
export class ContentCallToActionSquidexComponent implements OnInit, OnDestroy {
  @Input() contentScully: string
  @Input() styleClass: string
  @Input() contentCTA: string
  @Input() contentCTA_1: string
  @Input() contentCTA_2: string
  @Input() contentTextLink: string
  @Input() isTotalPartners = false
  @Input() isGoogleWorkspace = false
  @Input() isPricing = false
  @Input() isMobilePOS = false
  @Input() isBusinessRegistration = false
  @Input() previousPath = SignupPreviousPath.FLOWACCOUNT
  public currentPath = ''
  public serviceList: any
  private subscriptionService: Subscription[] = []
  constructor(
    public translate: TranslateService,
    public readonly squidexService: SquidexService,
    protected tstate: TransferStateService,
    public cdref: ChangeDetectorRef,
    private signupFlowService: SignupFlowService,
  ) {}

  ngOnInit(): void {
    if (isScullyGenerated()) {
      this.tstate.useScullyTransferState(this.contentScully, this.serviceList).subscribe((data: object[]) => {
        this.serviceList = data
        this.cdref.markForCheck()
      })
    } else {
      this.getTotalService()
    }
  }
  async getTotalService() {
    this.subscriptionService.push(
      this.squidexService
        .getContentSquidexApi('number-of-accounting-firm')
        .pipe(take(1))
        .subscribe((data: object[]) => {
          this.serviceList = data
          this.tstate.setState(this.contentScully, data)
          this.cdref.markForCheck()
        }),
    )
  }
  ngOnDestroy() {
    this.subscriptionService.forEach((x) => x.unsubscribe())
  }
  getLink(): string {
    const lang = this.translate.currentLang
    if (this.isGoogleWorkspace) {
      return 'https://form.flowaccount.com/googleworkspace'
    } else if (this.isTotalPartners) {
      return 'https://form.flowaccount.com/request-become-partner'
    } else if (this.isPricing) {
      return '/pricing'
    } else if (this.isMobilePOS) {
      return 'https://form.flowaccount.com/demo-request-mobile-application'
    } else if (this.isBusinessRegistration) {
      return 'https://form.flowaccount.com/th-th/businessregistrationservice'
    } else {
      return `https://auth.flowaccount.com/${lang}/Account/Register`
    }
  }

  onSignupEvent(): void {
    this.signupFlowService.setCookie(this.previousPath, true)
  }
}
